<template>
  <div :class="$style.buttons">
    <a-divider v-if="other">ODER</a-divider>
    <a v-if="authUrl" :href="authUrl" target="_self" :class="$style.login" class="default-link">
      <span>{{ $t('components.EBayLogin.continue') }} </span>
      <img src="~assets/img/ebay-logo-white.png" alt="eBay Logo">
    </a>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  other: {
    type: Boolean,
    default: false
  },
  channel: Number
});

const rootStore = useRootStore();

const {workspace, workspaces} = storeToRefs(rootStore);
const count = computed(() => Object.values(workspaces.value).reduce((p: any, w: any) => p + Object.values(w.channels).filter((c: any) => c.type === 'EBAY').length, 0));
const prompt = computed(() => count.value > 0);

const config = useRuntimeConfig();
const {capture} = useLog();
const authUrl = ref('');

try {
  const data = await useAPI('/ebay/login/url', {
    params: {
      workspace: workspace.value?.id,
      channel: props.channel,
      stage: config.public.stage
    }
  });
  authUrl.value = data.authUrl + (prompt ? '&prompt=login' : '');
} catch (error) {
  capture(error);
}

</script>

<style lang="scss" module>
.login {
  display: flex;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 7px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #000;
  height: 40px;

  &:hover {
    background-color: #333;
  }

  > span {
    color: #fff;
  }

  > img {
    max-width: 48px;
    height: auto;
    margin-left: 5px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
